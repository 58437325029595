import React from 'react'
import queryString from 'query-string'

import { PageLayout, AccountLostPasswordForm } from '../components'

const ContactTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  let id_customer = ''
  let token = ''
  let reset_token = ''
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    id_customer = qs.id_customer ? +qs.id_customer : null
    token = qs.token ? qs.token : ''
    reset_token = qs.reset_token ? qs.reset_token : ''
  }

  return (
    <PageLayout lang={lang} switcher={page}>
      <>
        {id_customer && token ? (
          <AccountLostPasswordForm lang={lang} token={token} reset_token={reset_token} id_customer={id_customer} />
        ) : (
          <AccountLostPasswordForm lang={lang} />
        )}
      </>
    </PageLayout>
  )
}

export default ContactTemplate
